import React, { useEffect, useState } from "react";
import Button from "components/Button";
import Layout from "components/OldLayout";
import { useHistory, useLocation } from "react-router-dom";
import * as S from "./styles";
import Repository from "repository";
import CarouselCard from "views/cardDesign/components/carouselCard";
import constants from "utils/constants";
import useApi from "hooks/useApi";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Loading from "components/Loading";
import { cpfMask, phoneMask } from "utils/mask";

const ChangeCategory = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [phoneZeuss, setPhoneZeuss] = useState("");
  const [msgDialog, setMsgDialog] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const operador = Repository.storage.get(Repository.keys.USER);
  const [cardDesign, setCardDesign] = useState([]);
  const [cardInformation, setCardInformation] = useState([]);
  const [designSelected, setDesignSelected] = useState();
  const handleSelect = (card) => setDesignSelected(card);
  const handleCardInformation = (cardInfo) => {
    if (
      cardInfo &&
      typeof cardInfo === "object" &&
      cardInfo.hasOwnProperty("categoria")
    ) {
      if (cardInfo.categoria === "Platinum") {
        setCardInformation({
          ...cardInfo,
          nome: "Plano Platinum",
          linkContrato: cardInfo.link,
          valorMensalidade: cardInfo.valor,
        });
      } else {
        setCardInformation(cardInfo);
      }
    } else {
      setCardInformation(cardInfo);
    }
  };

  const GetThemeListCreditCard = useApi(
    constants.endpoints.getThemeListCreditCard,
    "GET"
  );

  const PostValidateZeussPhone = useApi(
    constants.endpoints.postValidateZeussPhone
      .replace("PARAM_CPF", cpfMask(state?.data?.cpf).unmasked)
      .replace(
        "PARAM_PHONE",
        !phoneZeuss ? "" : phoneMask(phoneZeuss).unmasked
      ),
    "POST"
  );

  const POSTAddLeads = useApi(constants.endpoints.postAddLeads, "POST");

  const handleAddEventService = async () => {
    try {
      const payload = {
        celular: phoneMask(phoneZeuss).unmasked,
        cpf: cpfMask(state?.data?.cpf).unmasked,
        email: state?.data.email,
        nome: state?.data.nome,
        operador: {
          idOperador: operador?.id,
          nome: operador?.nomeTitular,
          perfil: (
            operador?.perfis?.filter((profile) => profile !== "USUARIO") || []
          ).join(","),
        },
        origem: "PROPOSTA",
        produto: "CARTAO",
        tipoEvento: "UPGRADE",
      };

      const response = await POSTAddLeads.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        history.push("change-category-signature", {
          ...state,
          idEvent: response.data?.id,
          upgradeCardInfo: cardInformation,
          themeSelected: designSelected,
          phone: phoneMask(phoneZeuss).unmasked,
        });
      } else {
        setMsgDialog(response.data?.mensagem);
        setDialogIsOpen(true);
      }
    } catch (error) {
      setDialogIsOpen(true);
      setMsgDialog("Erro ao criar evento!");
      console.error(error);
    }
  };

  const getThemeCard = async () => {
    try {
      const response = await GetThemeListCreditCard.callApi();
      if (response.status >= 200 && response.status < 300) {
        setCardDesign(response.data.data[0]);
      }
    } catch (error) {
      setMsgDialog(error.message);
      setDialogIsOpen(true);
    }
  };

  useEffect(() => {
    getThemeCard();
  }, []);

  const handleSubmitZeuss = async () => {
    try {
      const response = await PostValidateZeussPhone.callApi();

      if (response.status >= 200 && response.status < 300) {
        handleAddEventService();
      } else if (response.status >= 400) {
        setMsgDialog(response.mensagem);
        setDialogIsOpen(true);
      }
    } catch (err) {
      console.error(err.message);
      setMsgDialog(err.message);
      setDialogIsOpen(true);
    }
  };
  const activateButton = () => {
    if (
      designSelected === "PLATINUM" &&
      phoneMask(phoneZeuss)?.unmasked?.length !== 11
    ) {
      return true;
    } else if (!designSelected) {
      return true;
    } else {
      return false;
    }
  };

  const backToAttendanceDetail = () =>
    history.push("/attendance-detail", {
      id: state?.data?.id,
      cpf: state?.data?.cpf,
      idUsuario: state?.data?.idUsuario,
    });

  return (
    <S.Container>
      <Loading
        status={
          GetThemeListCreditCard.loading || PostValidateZeussPhone.loading
        }
      />
      <Layout goBack={backToAttendanceDetail} title="Alterar categoria">
        <OneButtonPopup
          defaultButton
          text={msgDialog}
          buttonText="Entendi"
          visibility={dialogIsOpen}
          setVisibility={() => setDialogIsOpen(false)}
          slug="/opcoes-acesso"
          onClose={() => history.push("/opcoes-acesso")}
        />
        <S.Content>
          <div style={{ width: "100%" }}>
            <S.Title>Escolha a categoria e tema que deseja alterar:</S.Title>
          </div>
          <S.Carousel>
            <CarouselCard
              cards={[cardDesign]}
              onChange={(e) => handleSelect(e)}
              onCardInformation={(e) => handleCardInformation(e)}
              onSelect={(phone) => setPhoneZeuss(phone)}
              notList={true}
            />
          </S.Carousel>
          <S.Footer>
            <Button
              buttonType="primary"
              text={"Continuar"}
              onClick={() => handleSubmitZeuss()}
              disabled={activateButton()}
              loading={POSTAddLeads.loading}
              className="button"
            />
          </S.Footer>
        </S.Content>
      </Layout>
    </S.Container>
  );
};

export default ChangeCategory;
