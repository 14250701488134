/* eslint-disable react-hooks/exhaustive-deps */
import useApi from "hooks/useApi";
import React, {
  useState,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import constants from "utils/constants";

export const AttendanceContext = createContext({});

const AttendanceProvider = ({ children }) => {
  const [cpf, setCpf] = useState(null);
  const [idProposta, setIdProposta] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clientDetail, setClientDetail] = useState(null);
  const [clientAttendanceDetail, setClientAttendanceDetail] = useState(null);
  const [clientDocuments, setClientDocuments] = useState(null);

  const [avaliableBenefits, setAvaliableBenefits] = useState(null);
  const [contractedBenefit, setContractedBenefits] = useState(null);
  const [benefitDependents, setBenefitDependents] = useState(null);

  const [cardToken, setCardToken] = useState(null);

  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    return () => {
      setCpf(null);
      setIdProposta(null);
      setCardNumber(null);
      setError(null);
      setMessage(null);
    };
  }, []);
  const GetClientDetail = useApi(
    constants.endpoints.getClientDetail.replace("PARAM_QUERY_STRING", cpf),
    "GET"
  );
  const GetClientAttendanceDetail = useApi(
    constants.endpoints.getClientAttendanceDetail.replace("PARAM_QUERY_STRING", cpf),
    "GET"
  );
  const GetClientDocument = useApi(
    constants.endpoints.getClientDocument.replace(
      "PARAM_QUERY_STRING",
      idProposta
    ),
    "GET"
  );
  const GetContractedBenefits = useApi(
    constants.endpoints.getBenefitById.replace("PARAM_ID_PROPOSAL", idProposta),
    "GET"
  );

  const GetAvaliableBenefits = useApi(
    constants.endpoints.getBenefit.replace(
      "PARAM_CPF",
      cpf
    ),
    "GET"
  );
  const GetBenefitDependents = useApi(
    constants.endpoints.getBenefitDependents.replace(
      "PARAM_ID_PROPOSAL",
      idProposta
    ),
    "GET"
  );

  const DeleteBenefit = useApi(
    constants.endpoints.deleteBenefit.replace("PARAM_ID_BENEFIT", idProposta),
    "DELETE"
  );
  const PostSecondWayCard = useApi(
    constants.endpoints.postDuplicateCard,
    "POST"
  );

  const PostTokenCard = useApi(constants.endpoints.postTokenCard, "POST");
  const PostValidatePassword = useApi(
    constants.endpoints.postValidPassword,
    "POST"
  );

  const GetFatura = useApi(
    constants.endpoints.getSearchFatura.replace(
      "PARAM_QUERY_STRING",
      `cpf=${cpf}&numeroCartao=${cardNumber}`
    ),
    "GET"
  );
  const PostSendEmail = useApi(
    constants.endpoints.postEmailFatura.replace(
      "PARAM_QUERY_STRING",
      `${cpf}/${selectedInvoice?.invoice?.mesReferencia}/${selectedInvoice?.invoice?.anoReferencia}/${clientDetail?.email}`
    ),
    "POST"
  );

  const DeleteDependent = useApi(
    constants.endpoints.deleteBenefitDependent,
    "DELETE"
  );

  const GetFaturaPdf = useApi(
    constants.endpoints.getGeraFatura.replace(
      "PARAM_QUERY_STRING",
      `${cpf}/${selectedInvoice?.invoice?.mesReferencia}/${selectedInvoice?.invoice?.anoReferencia}`
    ),
    "GET"
  );

  const PostBenefit = useApi(constants.endpoints.putBenefitAttendance, "PUT");
  const DeleteCancelBenefit = useApi(
    constants.endpoints.cancelBenefit,
    "DELETE"
  );

  const CancelBenefitCuida = useApi(
    constants.endpoints.cancelBenefitCuida,
    "DELETE"
  );

  useEffect(() => {
    if (
      GetClientDetail.loading ||
      GetClientAttendanceDetail.loading ||
      GetClientDocument.loading ||
      GetContractedBenefits.loading ||
      GetAvaliableBenefits.loading ||
      GetBenefitDependents.loading ||
      DeleteBenefit.loading ||
      PostSecondWayCard.loading ||
      PostTokenCard.loading ||
      PostValidatePassword.loading ||
      GetFatura.loading ||
      PostSendEmail.loading ||
      DeleteDependent.loading ||
      GetFaturaPdf.loading ||
      PostBenefit.loading ||
      DeleteCancelBenefit.loading ||
      CancelBenefitCuida.loading
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [
    GetClientDetail.loading,
    GetClientAttendanceDetail.loading,
    GetClientDocument.loading,
    GetContractedBenefits.loading,
    GetAvaliableBenefits.loading,
    GetBenefitDependents.loading,
    DeleteBenefit.loading,
    PostSecondWayCard.loading,
    PostTokenCard.loading,
    PostValidatePassword.loading,
    GetFatura.loading,
    PostSendEmail.loading,
    DeleteDependent.loading,
    GetFaturaPdf.loading,
    PostBenefit.loading,
    DeleteCancelBenefit.loading,
    CancelBenefitCuida.loading,
  ]);

  const cancelBenefitCuida = useCallback(
    async (payload) => {
      const { status, data, mensagem } = await CancelBenefitCuida.callApi(
        payload
      );

      return { status, data, mensagem };
    },
    [PostBenefit]
  );

  const cancelBenefit = useCallback(
    async (payload) => {
      const { status, data, mensagem } = await DeleteCancelBenefit.callApi(
        payload
      );

      return { status, data, mensagem };
    },
    [PostBenefit]
  );
  const postBenefit = useCallback(
    async (payload) => {
      const { status, data, mensagem } = await PostBenefit.callApi(payload);

      return { status, data, mensagem };
    },
    [PostBenefit]
  );
  const getFaturaPdf = useCallback(async () => {
    const { status, data, mensagem } = await GetFaturaPdf.callApi();

    return { status, data, mensagem };
  }, [GetFaturaPdf]);
  const deleteDependent = useCallback(
    async (payload) => {
      const { status, data, mensagem } = await DeleteDependent.callApi(payload);

      return { status, data, mensagem };
    },
    [DeleteDependent]
  );
  const postSendEmail = useCallback(async () => {
    const { status, data, mensagem } = await PostSendEmail.callApi();

    if (status >= 200 && status <= 300) {
      setMessage("Fatura enviada com sucesso para o e-mail!");
    }
    if (status < 200 || status > 300) {
      setError(true);
      setMessage(
        `Não foi possível enviar a fatura por e-mail! Status do erro: ${
          mensagem || status
        } `
      );
      return { isError: true, status, mensagem, data };
    }
    return { status, data, mensagem };
  }, [PostSendEmail]);
  const getFatura = useCallback(async () => {
    const { status, data, mensagem } = await GetFatura.callApi();
    if (status < 200 || status > 300) {
      setError(true);
      setMessage(mensagem ?? "Erro ao buscar faturas");
      return { isError: true, status, mensagem, data };
    }
    return { status, data, mensagem };
  }, [GetFatura]);
  const postValidatePassword = useCallback(
    async (payload) => {
      const { status, mensagem } = await PostValidatePassword.callApi(payload);
      let isError = false;
      if (status < 200 || status > 300) {
        setError(true);
        setMessage(mensagem ?? "Senha inválida, tente novamente.");
        return { isError: true, status, mensagem };
      }
      return { isError, status, mensagem };
    },
    [PostValidatePassword]
  );
  const postTokenCard = useCallback(
    async (payload) => {
      const { data, status, mensagem } = await PostTokenCard.callApi(payload);
      const { tokenCartao } = data;
      if (status < 200 || status > 300) {
        setError(true);
        setMessage(mensagem ?? "");
        return { isErrorToken: true };
      }

      return { status, tokenCartao, mensagem };
    },
    [PostTokenCard]
  );
  const postSecondWayCard = useCallback(
    async (payload) => {
      const { status, mensagem } = await PostSecondWayCard.callApi(payload);

      if (status < 200 || status > 300) {
        setError(true);
        setMessage(mensagem ?? "Falha ao solicitar segunda via do cartão!");
      }
      if (status >= 200 && status <= 300) {
        setMessage("Segunda via do cartão solicitada com sucesso!");
      }
      return { status, mensagem };
    },
    [PostSecondWayCard]
  );
  const deleteBenefit = useCallback(async () => {
    const { data, status, mensagem } = await DeleteBenefit.callApi();

    if (status < 200 || status > 300) {
      setError(true);
      setMessage(
        mensagem ?? "Erro ao deletar benefício. Tente novamente mais tarde."
      );
    }
    if (status >= 200 && status <= 300) {
      setContractedBenefits(data);
    }
  }, [DeleteBenefit]);
  const getBenefitDependents = useCallback(async () => {
    const { data, status, mensagem } = await GetBenefitDependents.callApi();

    if (status < 200 || status > 300) {
      setError(true);
      setMessage(mensagem);
    }
    if (status >= 200 && status <= 300) {
      setBenefitDependents(data);
    }
  }, [GetBenefitDependents]);
  const getContractedBenefits = useCallback(async () => {
    const { data, status, mensagem } = await GetContractedBenefits.callApi();

    if (status < 200 || status > 300) {
      setError(true);
      setMessage(mensagem);
    }
    if (status >= 200 && status <= 300) {
      setContractedBenefits(data);
    }
  }, [GetContractedBenefits]);
  const getAvaliableBenefits = useCallback(async () => {
    const { data, status, mensagem } = await GetAvaliableBenefits.callApi();

    if (status < 200 || status > 300) {
      setError(true);
      setMessage(mensagem);
    }
    if (status >= 200 && status <= 300) {
      const avaliableBenefits = data.filter(item => item.disponivelContratacao);
      setAvaliableBenefits(avaliableBenefits);
    }
  }, [GetAvaliableBenefits]);
  const getClientDocuments = useCallback(async () => {
    const { data, status, mensagem } = await GetClientDocument.callApi();

    if (status >= 200 && status <= 300) {
      setClientDocuments(data);
    }
    if (status < 200 || status > 300) {
      setError(true);
      setMessage(mensagem);
    }
  }, [GetClientDocument]);

  const getClientDetail = useCallback(async () => {
    const { data, status, mensagem } = await GetClientDetail.callApi();

    if (status >= 200 && status <= 300) {
      setClientDetail(data);
    }
    if (status < 200 || status > 300) {
      setError(true);
      setMessage(mensagem);
    }
  }, [GetClientDetail]);

  const getClientAttendanceDetail = useCallback(async () => {
    const { data, status, mensagem } = await GetClientAttendanceDetail.callApi();

    if (status >= 200 && status <= 300) {
      setClientAttendanceDetail(data);
    }
    if (status < 200 || status > 300) {
      setError(true);
      setMessage(mensagem);
    }
  }, [GetClientAttendanceDetail]);

  useEffect(() => {
    if (idProposta) {
      getClientDocuments();
    }
  }, [idProposta]);

  return (
    <AttendanceContext.Provider
      value={{
        idProposta,
        setIdProposta,
        clientDocuments,
        clientDetail,
        clientAttendanceDetail,
        getAvaliableBenefits,
        avaliableBenefits,
        setCpf,
        cpf,
        message,
        error,
        loading,
        getContractedBenefits,
        contractedBenefit,
        benefitDependents,
        getBenefitDependents,
        deleteBenefit,
        postSecondWayCard,
        postTokenCard,
        postValidatePassword,
        cardToken,
        setCardNumber,
        getFatura,
        setSelectedInvoice,
        postSendEmail,
        selectedInvoice,
        deleteDependent,
        getFaturaPdf,
        postBenefit,
        cancelBenefit,
        cancelBenefitCuida,
        getClientDetail,
        setClientDetail,
        getClientAttendanceDetail,
        setClientAttendanceDetail
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
};
export default AttendanceProvider;
