const externalAccess = {
  guestUser: process.env.REACT_APP_USUARIO_VISITANTE,
  guestPassword: process.env.REACT_APP_SENHA_VISITANTE,
  originMobile: process.env.REACT_APP_ID_ORIGEM_MOBILE,
  originSite: process.env.REACT_APP_ID_ORIGEM_SITE,
  originStore: process.env.REACT_APP_ID_ORIGEM_LOJA,
  originTotem: process.env.REACT_APP_ID_ORIGEM_TOTEM,
};

const endpoints = {
  login: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/auth/login`,
  sendProposalToEmail: `${process.env.REACT_APP_MS_EMAIL}/v1/email/enviar-email/PARAM_ID_PROPOSAL/PARAM_EMAIL`,
  validateProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/validar-proposta/PARAM_CPF`,
  validateProposalNoAuth: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/noauth/validar-proposta/PARAM_CPF`,
  initialProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/proposta-inicial`,
  postCriaP1: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/criar-proposta-p1`,
  initialProposalNoAuth: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/noauth/proposta-inicial`,
  spcProposalData: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/dados-proposta-spc/PARAM_ID_PROPOSAL`,
  getReasons: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-motivo-refazer-proposta/PARAM_ID_PROPOSAL`,
  createProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/complementar-proposta`,
  postComplementarP2Senff: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/complementar-proposta-p2`,
  saveContact: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/salvar-contato/PARAM_ID_PROPOSAL`,
  proposalClientModal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-cliente-proposta/PARAM_ID_PROPOSAL`,
  findAddressByCep: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/cep/PARAM_CEP`,
  getClientByCpf: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/usuario-porCpf/PARAM_CPF`,
  sendLinkRedefinePassword: `${process.env.REACT_APP_MS_EMAIL}/v1/email/noauth/link-senha-email-proposta`,
  saveClientIdentification: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/salvar-identificacao-cliente`,
  validateCertiface: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/validacao-certiface`,
  saveDesignCreditCard: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/salvar-tema-cartao/PARAM_CPF/PARAM_DESIGN?celularZeuss=PARAM_PHONE_ZEUSS`,
  getProposalStatus: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-status-propostas?PARAM_QUERY_STRING`,
  getClient: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-ativo?PARAM_QUERY_STRING`,
  getClientDetail: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/buscar-cliente-cpf/PARAM_QUERY_STRING`,
  getClientAttendanceDetail: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/cadastro-proposta-attendance-detail-buscar-cliente-cpf/PARAM_QUERY_STRING`,
  getClientDocument: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/obter-documentos-identificacao/PARAM_QUERY_STRING`,
  getSearchFatura: `${process.env.REACT_APP_MS_RELATORIOS}/v1/relatorios/obter-lista-faturas?PARAM_QUERY_STRING`,
  getGeraFatura: `${process.env.REACT_APP_MS_RELATORIOS}/v1/relatorios/gerar-fatura/PARAM_QUERY_STRING`,
  postDuplicateCard: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/reemissao`,
  postValidPassword: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/noauth/validar-senha`,
  postTokenCard: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/noauth/token-cartao`,
  postEmailFatura: `${process.env.REACT_APP_MS_EMAIL}/v1/email/email-fatura/PARAM_QUERY_STRING`,
  getProposalsByStatus: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/buscar-propostas-status-paginacao`,
  defineNewPassword: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/noauth/confirmar-alterar-senha-backoffice`,
  signProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/assinar-proposta`,
  signProposalBenefit: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/assinar-beneficio-proposta`,
  getProposalPdfById: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/noauth/proposta-assinatura?id=PARAM_ID_PROPOSAL`,
  getBenefit: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/obter-beneficios?cpf=PARAM_CPF`,
  getBenefitDependents: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/obter-dependentes-odonto/PARAM_ID_PROPOSAL`,
  getBenefitById: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/buscar-beneficio-proposta/PARAM_ID_PROPOSAL`,
  getBenefitNotContractedByIdProposal: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/buscar-beneficios-nao-contratados/PARAM_ID_PROPOSAL`,
  postBenefitById: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/salvar-beneficio-proposta`,
  putBenefitAttendance: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/salvar-beneficio-proposta-atendimento`,
  postDependent: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/adicionar-dependente/PARAM_ID_PROPOSAL`,
  refuseProposal: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/recusar-proposta/PARAM_ID_PROPOSAL`,
  livenessCredential: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/credencial`,
  livenessAppKey: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/appkey`,
  livenessChallenge: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/challenge`,
  livenessCaptcha: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/captcha`,
  livenessResult: `${process.env.REACT_APP_MS_INTEGRACAO}/v1/integracoes/certiface/result`,
  signOut: `${process.env.REACT_APP_MS_USUARIO}/v1/usuarios/auth/logout`,
  sendCode: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/enviar-codigo-login`,
  deleteBenefit: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/PARAM_ID_PROPOSAL/PARAM_ID_BENEFIT`,
  cancelBenefit: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/cancelamento-seguros-tokio`,
  cancelBenefitCuida: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/cancelamento-beneficio-cuida`,
  getBenefitSignedContract: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/buscar-contrato-seguros/PARAM_ID_PROPOSAL/PARAM_ID_BENEFIT`,
  putCodeValidate: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/push/validar-celular`,
  postSendPushSMS: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/sms/enviar-sms-notificacao-celular`,
  postSendTokenWhatsApp: `${process.env.REACT_APP_MS_NOTIFICACAO}/v1/notificacoes/whatsapp/enviar-whatsapp-codigo-proposta`,
  deleteBenefitDependent: `${process.env.REACT_APP_MS_BENEFICIO}/v1/beneficio/excluir-beneficio-dependente`,
  getStatusProposalUser: `${process.env.REACT_APP_MS_USUARIO}/v1/ficha-proposta/obter-status-propostas/PARAM_QUERY`,
  postStatusProposalUserPagination: `${process.env.REACT_APP_MS_USUARIO}/v1/ficha-proposta/buscar-propostas-status-paginacao/PARAM_QUERY`,
  getSpcDataUser: `${process.env.REACT_APP_MS_USUARIO}/v1/ficha-proposta/dados-proposta-spc/PARAM_ID`,
  getListProposalRealTime: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-propostas/listar-propostas-status-D0/PARAM_NAME`,
  getListProposalDay: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-propostas-status-D1/PARAM_NAME?data=PARAM_DATA`,
  getListProposalMonth: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-dashboard/listar-propostas-status-mensal/PARAM_NAME?dataFinal=PARAM_DATA_FINAL&dataInicial=PARAM_DATA_INICIAL`,
  getListBenefitDay: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-beneficios/obter-adesoes-operador-dia-anterior?nomeOperador=PARAM_NAME`,
  getListBenefitMonth: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-beneficios/obter-adesoes-operador-mensal?nomeOperador=PARAM_NAME`,
  getListBenefitRealTime: `${process.env.REACT_APP_MS_BOX_DASHBOARD}/v1/box-beneficios/obter-adesoes-operador-diario?nomeOperador=PARAM_NAME`,
  getListObtemLeads: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/obter-eventos/PARAM_CPF`,
  postAddLeads: `${process.env.REACT_APP_MS_EVENTOS}/v2/eventos/incluir-evento`,
  getRulesSimulate: `${process.env.REACT_APP_MS_CARTEIRA}/v1/carteiras/regras-pagamento-transacao-digital`,
  getOperatorBenefit: `${process.env.REACT_APP_MS_BENEFICIO}/v1/box-beneficios/listar-beneficios-historico-operador?data=PARAM_DATA&nomeOperador=PARAM_OPERADOR`,
  getOperatorProposal: `${process.env.REACT_APP_MS_BENEFICIO}/v1/box-dashboard/listar-propostas-historico-operador?data=PARAM_DATA&nomeOperador=PARAM_OPERADOR`,
  searchClientByCpf: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-cliente-cpf/PARAM_CPF`,
  searchClientByCPFWithdraw: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-cliente-emprestimo-saque-avista-dados-pessoais/PARAM_CPF`,
  // emprestimo
  getClientDataCpf: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/noauth/buscar-cliente-cpf-emprestimo/PARAM_CPF`,
  putClientLoanAddress: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/atualizar-cliente/emprestimo/endereco`,
  putClientLoan: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/atualizar-cliente/emprestimo`,
  postValidateKycFreezeList: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/valida-situacao-kyc/PARAM_CPF`,
  postSimulateCreditLimit: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/simular/credito-limite`,
  postSimulateAvailableValue: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/simular/valor-liberado`,
  postSimulateInstallmentValue: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/simular/valor-parcela`,
  postLoanAttendance: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/contratar`,
  getBanks: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/buscar-bancos`,
  checkClientCpfEmail: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/clientes/noauth/validar-email-disponivel-cliente/PARAM_EMAIL/PARAM_CPF`,
  getavailableWithDrawLimit: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/box-base-clientes/limite-disponivel-saque?cpf=PARAM_CPF`,
  postSimulateWithDraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/simular`,
  postWithDrawByTeuCard: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/contratar/ID_LOAN`,
  getContractWithDrawByTeuCard: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/contrato-emprestimo-pdf-online/ID_LOAN`,
  getContractTerms: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/contrato-emprestimo-pdf-online/ID_LOAN`,
  postSignTerms: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/assinar-digitalmente-contrato`,
  putUpdateAdditionalDataWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/atualiza-dados-adicionais-cliente/ID_LOAN`,
  putUpdateBankingDataWithdraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/atualiza-dados-bancario-cliente/ID_LOAN`,
  putCancelWithDraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/saque/cancelar/PARAM_ID`,
  getPendingWithDraw: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/emprestimo-pendente-celcoin/PARAM_CPF`,
  postIncludeClientEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/incluir-cliente-easy-credito`,
  postSearchProposalsEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/buscador-easy-credito`,
  getProfessionsByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-profissoes-easy-credito`,
  getBanksByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-bancos-easy-credito`,
  getInssuingByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-emissores-easy-credito`,
  postSendDocumentsByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/enviar-documento-easy-credito/ID_LOAN`,
  getProposalsByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-propostas/PARAM_CPF`,
  getLastProposalsByEasyCredito: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-ultima-proposta/PARAM_CPF`,
  getThemeListCreditCard: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/listar-categorias-cartao`,
  postValidateZeussPhone: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/validar-celular-zeuss/PARAM_CPF?celularZeuss=PARAM_PHONE`,
  getPlatinumTerms: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/contrato-platinum-assinado/PARAM_ID_PROPOSAL`,
  getClientCategoryCard: `${process.env.REACT_APP_MS_FICHA_AUTORIZADOR}/v1/autorizador/listar-categorias-cartao-atual/PARAM_CPF`,
  postChangeCategoryCard: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/alterar-categoria-cartao/PARAM_CPF/PARAM_THEME`,
  postExecucuteEvent: `${process.env.REACT_APP_MS_EVENTOS}/v1/eventos/executar-evento`,
  postSaveChosenBenefits: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/salvar-contratos-selecionados`,
  postSaveBenefitSignature: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/salvar-assinatura-contrato`,
  getSavedBenefitsSignatures: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/buscar-contratos-selecionados`,
  deleteSavedBenefits: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/excluir-assinaturas-contratos?idProposta=PARAM_ID_PROPOSTA`,
  postPrepareCreateContracts: `${process.env.REACT_APP_MS_FICHA_PROPOSTA}/v1/ficha-proposta/iniciar-geracao-contratos-proposta`,
  postWebsocketContractGeneration: `${process.env.REACT_APP_WEBSOCKET_PAYMENT}/v1/websockets/ficha-proposta/geracao-contratos-assinados/PARAM_ID_PROPOSTA`,
  postValidateAnfitriao: `${process.env.REACT_APP_MS_ANFITRIAO}/v1/anfitriao/validar-anfitriao-ativo/PARAM_ID`,
  getStatusClientKYC: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/obter-situacao-kyc/PARAM_CPF`,
  postCreateSolicitationKYC: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/criar-solicitacao-kyc`,
  postCreateTimeout: `${process.env.REACT_APP_MS_EMPRESTIMO}/v1/emprestimos/criar-log-tempo-expirado-kyc`,
  postWebsocketProposal: `${process.env.REACT_APP_WEBSOCKET_PAYMENT}/v1/websockets/ficha-proposta/aprovacao-proposta/PARAM_ID_PROPOSTA`,
  getReprintCard: `${process.env.REACT_APP_MS_CLIENTE}/v1/clientes/reimprimir-cartao?cpf=PARAM_CPF&operador=PARAM_OPERADOR`,
};

export default {
  externalAccess,
  endpoints,
};
