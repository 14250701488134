/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import Layout from "components/OldLayout";
import Camera, { FACING_MODES } from "components/NewCamera";
import * as St from "./styles";
import assets from "assets";
import Button from "components/Button";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import PictureModal from "components/PictureCard";
import Spinner from "components/Spinner";
import FaceCaptchaService, {
  getCertifaceResultMessage,
  getImageEnc,
} from "services/facecaptcha/facecaptcha";
import OneButtonPopup from "components/modals/OneButtonPopup";

const IMAGE_TYPES = {
  CERTIFACE: "CERTIFACE",
};

const LivennessReprintCard = () => {
  const [userPicture, setUserPicture] = useState();
  const [certiface, setCertiface] = useState({});
  const [certifacePopupVisibility, setCertifacePopupVisibility] =
    useState(false);
  const [certifaceErrorMessage, setCertifaceErrorMessage] = useState("");
  const [camUserPictureIsOpen, setCamUserPictureIsOpen] = useState(false);
  const [camUserPictureIsClose, setCamUserPictureIsClose] = useState(false);
  const [certifaceImages, setCertifaceImages] = useState([]);
  const [firstPictureCertiface, setFirstPictureCertiface] = useState(null);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [certifaceMessagebase64, setCertifaceMessagebase64] = useState(null);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [errorUserPicture] = useState(null);
  const [countSnapNow, setCountSnapNow] = useState(0);
  const [loadValidateFace, setLoadValidateFace] = useState(false);

  const history = useHistory();

  const livenessRef = useRef();
  const onTakeFirst = useRef();

  let countrSnapEnd = 0;

  const { state } = useLocation();

  const { data } = state;

  console.log(state);

  const dataNascimento = moment(data?.dataNascimento, "YYYY-MM-DD").format(
    "DD/MM/YYYY"
  );

  const checkIfLivenessFormValid = () => {
    if (!data?.nome) return { field: "Nome Completo ", valid: false };
    if (!dataNascimento) return { field: "Data de Nascimento ", valid: false };

    return { field: "", valid: true };
  };

  const toggleCameraUserPicture = async () => {
    const isFormValid = checkIfLivenessFormValid();

    if (!isFormValid.valid) {
      setCertifaceErrorMessage(
        isFormValid.field + "obrigatório para iniciar a Prova de Vida"
      );
      setCertifacePopupVisibility(true);
      return;
    }

    countrSnapEnd = 0;
    setCamUserPictureIsClose(false);

    setCamUserPictureIsOpen((prev) => !prev);
  };

  const forceCameraTakePicture = (time, obj) => {
    console.log("Iniciei forceCameraTakePicture ");

    setTimeout(() => {
      let count = 0;
      setCurrentChallenge(obj);
      setCertifaceMessagebase64(obj.message);
      const image = setInterval(async () => {
        count++;

        if (
          count === obj.numberSnap &&
          obj.numberOfChallenges - 1 === countrSnapEnd
        ) {
          setCamUserPictureIsClose(true);
        }

        if (livenessRef.current) {
          await livenessRef.current.takePicture();
        }
      }, 4000);

      const challenge = setInterval(function () {
        clearInterval(image);
        clearInterval(challenge);
        countrSnapEnd++;
        setLoadValidateFace(true);
      }, time);
    }, (time + 1000) * obj.index);
  };

  const startLiveness = async () => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);
    const service = FaceCaptchaService();
    setCertifaceImages([]);

    const startResponse = await service.start(
      data?.nome || "",
      dataNascimento || "00/00/0000",
      data?.cpf || ""
    );

    setLoadValidateFace(false);

    if (startResponse.status === 200) {
      const challenges = startResponse.data?.challenge?.challenges || [];

      if (challenges.length > 0) {
        challenges.forEach(async (ch, index) => {
          const code = ch.tipoFace.codigo;
          const time = ch.tempoEmSegundos * 1000;
          const size = challenges.length - 1;
          const totalTime = startResponse.data?.challenge?.totalTime;
          const snapInMillis =
            startResponse.data?.challenge?.snapFrequenceInMillis;
          const numberSnap = startResponse.data?.challenge?.snapNumber;
          const numberOfChallenges =
            startResponse.data?.challenge?.numberOfChallenges;

          forceCameraTakePicture(time, {
            code,
            time,
            index,
            size,
            message: ch.mensagem,
            startResponse,
            totalTime,
            snapInMillis,
            numberSnap,
            numberOfChallenges,
          });
        });
      }
    }
    setImageIsLoading(null);
  };

  const sendChallenges = async (startResponse, images, firstImage) => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);

    const service = FaceCaptchaService();

    const challengeResponse = await service.captcha(startResponse, images);

    setImageIsLoading(null);

    setCertiface(challengeResponse.data?.result);

    setCertifaceErrorMessage("");

    setLoadValidateFace(false);

    if (challengeResponse.success === true) {
      const codeResult = challengeResponse.data.result.codID;
      if ([1.1, 1.2].includes(codeResult)) {
        setUserPicture(firstPictureCertiface);
        history.push("/cardTemplateReprint", {
          temaDoCartao: data?.temaDoCartao,
          cpf: data?.cpf,
        });
        return;
      }
      if ([200.1, 200.2].includes(codeResult)) {
        setUserPicture(firstImage || firstPictureCertiface);
        setCertifaceErrorMessage(
          getCertifaceResultMessage(challengeResponse.data.result.codID || 0)
        );
        setCertifacePopupVisibility(true);
        return;
      } else {
        setCertifaceErrorMessage(
          getCertifaceResultMessage(challengeResponse.data.result.codID || 0)
        );
        setCertifacePopupVisibility(true);
      }
    }
  };

  const invokeCameraHandle = (image) => {
    if (currentChallenge === null) return;

    const { code, index, size, message, startResponse } = currentChallenge;

    if (index === 0 && countSnapNow === 0) {
      onTakeFirst.current = image;
      setFirstPictureCertiface(image);
    }

    setCountSnapNow(countSnapNow + 1);

    const imageBase64 = image;

    const currentImages = certifaceImages;

    const newImage = getImageEnc(imageBase64, code);

    setCertifaceMessagebase64(message);

    currentImages.push(newImage);
    setCertifaceImages(currentImages);

    if (index === size && camUserPictureIsClose) {
      if (livenessRef.current) {
        livenessRef.current.stopCamera();
      }

      if (index === 0) {
        sendChallenges(startResponse, currentImages, imageBase64);
      } else {
        sendChallenges(startResponse, currentImages);
      }

      setCurrentChallenge(null);
      setCertifaceMessagebase64(null);
      setLoadValidateFace(false);
    }
  };

  return (
    <St.SafeView>
      <Layout
        routeBack={{
          pathname: "/attendance-detail",
          state: { cpf: state?.data?.cpf },
        }}
        title={loadValidateFace ? "Validar Face" : "Reconhecimento facial"}
      >
        <OneButtonPopup
          defaultButton
          text={certifaceErrorMessage}
          buttonText="OK"
          visibility={certifacePopupVisibility}
          setVisibility={() => {
            if ([300.1, 300.2].includes(certiface.codID)) {
              setCertifacePopupVisibility(false);
              history.push("/opcoes-acesso");
            } else {
              setCertifacePopupVisibility(false);
            }
          }}
        />
        <St.Picture>
          <St.WrapperCamera show={camUserPictureIsOpen}>
            <Camera
              open={camUserPictureIsOpen}
              facingMode={FACING_MODES.ENVIRONMENT}
              isLiveness
              isStartLiveness={startLiveness}
              onTakePhoto={invokeCameraHandle}
              certifaceMessagebase64={certifaceMessagebase64}
              stopWatch
              doNotCloseAfterTakePicture
              ref={livenessRef}
              handleClose={() => setCamUserPictureIsOpen(false)}
              disableTakePictureButton
              mask
            />
          </St.WrapperCamera>
        </St.Picture>
        {loadValidateFace ? (
          <St.Container>
            <St.TopSpace>
              <St.ContainerValidFace>
                <h1>Realizando validações finais.</h1>
                <h2>
                  Por favor, aguarde um instante, estamos validando a tua face.
                </h2>
              </St.ContainerValidFace>
            </St.TopSpace>
          </St.Container>
        ) : (
          <St.Container>
            <St.TopSpace>
              <St.Title>
                Precisamos realizar o reconhecimento facial do cliente para
                liberar a reimpressão.
              </St.Title>
              <St.ContainerIconRecognitionIcon>
                <img src={assets.png.faceRecognition} alt="" />
              </St.ContainerIconRecognitionIcon>
              <St.ContainerTips>
                <St.TipLine>
                  <img src={assets.png.checkCircleRed} alt="" />
                  <span>Escolha um local bem iluminado.</span>
                </St.TipLine>
                <St.TipLine>
                  <img src={assets.png.checkCircleRed} alt="" />
                  <span>
                    Remova mascára, boné, acessórios que possam dificultar tua
                    identificação
                  </span>
                </St.TipLine>
                <St.TipLine>
                  <img src={assets.png.checkCircleRed} alt="" />
                  <span>Posicione-se no local indicado pela câmera.</span>
                </St.TipLine>
              </St.ContainerTips>

              <St.Footer>
                <Button
                  buttonType="primary"
                  text="Continuar"
                  onClick={toggleCameraUserPicture}
                  disabled={imageIsLoading}
                />
              </St.Footer>
            </St.TopSpace>
          </St.Container>
        )}
      </Layout>
    </St.SafeView>
  );
};

export default LivennessReprintCard;
