/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import Button from "components/Button";
import Card from "components/Card";
import ContainerFlex from "components/ContainerFlex";
import Grid from "components/Grid";
import Input from "components/Input";
import InputRadio from "components/InputRadio";
import Loading from "components/Loading";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Layout from "components/OldLayout";
import Pagination from "components/Pagination";
import { GlobalContext } from "context/GlobalContext";
import useApi from "hooks/useApi";
import { useHistory } from "react-router-dom";
import Repository from "repository";
import themes from "styles";
import constants from "utils/constants";
import { transformInGlobalDate } from "utils/date";
import { cpfMask, dateMask } from "utils/mask";
import { FILTER_OPTIONS, PROPOSAL_STATUS } from "./constants";
import * as S from "./styles";

function SearchProposalQuery() {
  const history = useHistory();
  const { addProposta } = useContext(GlobalContext);

  const [name, setName] = useState("");
  const [from, setFrom] = useState({});
  const [to, setTo] = useState({});
  const [cpf, setCpf] = useState({});

  const [proposals, setProposals] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [idProposalIsLoading, setIdProposalIsLoading] = useState(-1);
  const [msgDialog, setMsgDialog] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [pendingDialogIsOpen, setpendingDialogIsOpen] = useState(false);
  const [optionSelected, setOptionSelected] = useState(FILTER_OPTIONS.APROVADA);
  const [showPagination, setShowPagination] = useState(false);
  const [urls, setUrls] = useState({
    urlSpc: null,
    urlBenefits: null,
    urlReasons: null,
  });
  const [viewData, setViewData] = useState(null);
  const [viewType, setViewType] = useState(null);

  useEffect(() => {
    if (
      optionSelected === FILTER_OPTIONS.APROVADA ||
      optionSelected === FILTER_OPTIONS.REPROVADA
    ) {
      searchStrategy();
    }
  }, [optionSelected]);

  function validDate(days) {
    const a = moment(from?.masked, "DD/MM/YYYY");
    const b = moment(to?.masked, "DD/MM/YYYY");
    const diffDays = b.diff(a, "days");

    if (diffDays <= days) {
      return true;
    }

    setMsgDialog(`Período informado maior que 30 dias.`);
    setDialogIsOpen(true);
    return false;
  }

  const createQueryString = useCallback(() => {
    let url = "";

    if (cpf.unmasked?.trim()?.length === 11) url = url + `cpf=${cpf?.unmasked}`;

    if (name.trim()?.length) url = url + `&nome=${name}`;

    if (to.masked?.length === 10)
      url = url + `&ate=${transformInGlobalDate(to.masked)}`;

    if (from.masked?.length === 10)
      url = url + `&de=${transformInGlobalDate(from.masked)}`;

    return url;
  }, [cpf, name, to, from]);

  const handleGenerateIdQuery = (id) => {
    setUrls({
      urlSpc: constants.endpoints.spcProposalData.replace(
        "PARAM_ID_PROPOSAL",
        id
      ),

      urlBenefits: constants.endpoints.getBenefitById.replace(
        "PARAM_ID_PROPOSAL",
        id
      ),
      urlReasons: constants.endpoints.getReasons.replace(
        "PARAM_ID_PROPOSAL",
        id
      ),
    });
  };

  const GetProposalStatus = useApi(
    constants.endpoints.getProposalStatus.replace(
      "PARAM_QUERY_STRING",
      createQueryString()
    ),
    "GET"
  );
  const GetProposalByStatus = useApi(
    constants.endpoints.getProposalsByStatus,
    "POST"
  );
  const GetBenefits = useApi(urls.urlBenefits, "GET");
  const GetReasons = useApi(urls.urlReasons, "GET");
  const GetSpcProposalData = useApi(urls.urlSpc, "GET");

  const handleFilterSearch = async () => {
    if (from?.masked && to?.masked && !validDate(29)) return;

    try {
      const response = await GetProposalStatus.callApi();

      setShowPagination(false);
      setProposals(response.data.buscaPropostaResponses);
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleApprovedOrDisapprovedSearch = useCallback(
    async (currentPage = 1) => {
      try {
        const { data } = await GetProposalByStatus.callApi({
          limitePorPagina: 8,
          pagina: currentPage,
          status: optionSelected,
        });

        setProposals(data.pagina.propostasResponse);
        setTotalPages(data.totalDePaginas);
        setShowPagination(true);
      } catch (err) {}
    },
    [optionSelected]
  );

  const searchStrategy = async () => {
    switch (optionSelected) {
      case FILTER_OPTIONS.FILTRO:
        await handleFilterSearch();
        break;
      default:
        handleApprovedOrDisapprovedSearch();
        break;
    }

    setCpf((prev) => ({ ...prev, masked: "", unmasked: "" }));
    setFrom((prev) => ({ ...prev, masked: "", unmasked: "" }));
    setTo((prev) => ({ ...prev, masked: "", unmasked: "" }));
    setName("");
  };
  const search = async () => {
    try {
      if (viewData?.status === PROPOSAL_STATUS.PENDENTE_MESA_CREDITO) {
        const { data } = await GetSpcProposalData.callApi();
        setMsgDialog(data?.proposta?.motivos[0]?.motivo);
        setpendingDialogIsOpen(true);
      } else {
        history.push("/status-proposta", { id: viewData.id });
      }
    } catch (error) {
      setMsgDialog(error?.mensagem);
    }
  };
  const reasons = async () => {
    const { status, mensagem, data } = await GetReasons.callApi();

    if (status >= 200 && status <= 300) {
      const message = `${data?.nome}: ${data?.descricaoMotivo}`;
      setMsgDialog(message);
      setpendingDialogIsOpen(true);
    } else {
      setMsgDialog(mensagem);
      setpendingDialogIsOpen(true);
    }
  };
  const GetBenefitNotContractedById = useApi(
    constants.endpoints.getBenefitNotContractedByIdProposal.replace(
      "PARAM_ID_PROPOSAL",
      viewData?.id
    ),
    "GET"
  );

  const GetBenefitsSignatures = useApi(
    constants.endpoints.getSavedBenefitsSignatures,
    "GET",
    {},
    {},
    true
  );
  const contract = async () => {
    try {
      const response = await GetSpcProposalData.callApi();
      const benefits = await GetBenefits.callApi();

      const signedBenefits = await GetBenefitsSignatures.callApi({
        idProposta: response.data.idProposta,
      });
      Repository.storage.set(Repository.keys.ID_PROPOSTA, viewData.id);

      const dateNow = new Date().getFullYear();
      const dateProposta = new Date(
        response?.data?.proposta?.dataNascimento
      )?.getFullYear();
      const dateSpc = new Date(
        response?.data?.spc?.dataNascimento
      )?.getFullYear();
      const isUserMoreThan65YearsOld =
        dateNow - dateProposta > 65 || dateNow - dateSpc > 65;
      if (signedBenefits.data?.length > 0) {
        history.push("/continuar-assinatura-proposta", {
          propostaId: viewData.id,
          cpf: viewData.cpf,
          isUserMoreThan65YearsOld,
          selectedContracts: signedBenefits.data,
        });
        return;
      }

      const benefitNotContracted = await GetBenefitNotContractedById.callApi();

      if (
        viewData.status === PROPOSAL_STATUS.APROVADOP2_NAO_ASSINADO ||
        viewData.status === PROPOSAL_STATUS.APROVADA_MESA_NAO_ASSINADO
      ) {
        Repository.storage.set(
          Repository.keys.EMAIL_PROPOSTA,
          response.data?.proposta?.cliente.email.trim()
        );
        if (
          benefitNotContracted.status >= 200 &&
          benefitNotContracted.status <= 300
        ) {
          if (benefitNotContracted.data?.length > 0) {
            history.push("/consulta-proposta-antes-assinatura", {
              propostaId: viewData.id,
              cpf: viewData.cpf,
              isUserMoreThan65YearsOld,
              proposalStatus: proposals[0]?.status,
              benefits: {
                contracted: benefits?.data?.beneficios,
                notContracted: benefitNotContracted.data,
              },
            });
          } else {
            history.push("/proposta", {
              propostaId: viewData.id,
              benefits: {
                contracted: benefits?.data?.beneficios,
                notContracted: benefitNotContracted?.data,
              },
            });
          }
        }
      } else if (viewData.status === PROPOSAL_STATUS.APROVADOP1) {
        const newProposal = Object.assign(response.data.spc, {
          id: viewData.id,
        });
        addProposta(newProposal);

        history.push("/cadastro-completo", {
          cpf: response.data?.spc.cpf,
          celular: response.data?.proposta.celular,
          email: response.data?.proposta.email,
          celular2: response.data?.proposta.celular2,
          nomeEmpresa: response.data?.proposta.nomeEmpresa,
          profissao: response.data?.proposta.profissao,
          checkboxSenff: response.data?.proposta?.origem === "SENFF", 
        });
      }
    } catch (err) {
      console.error(err.response);
    } finally {
      setIdProposalIsLoading(-1);
    }
  };
  useEffect(() => {
    if (viewData && viewType === "search") {
      search();
    }
    if (viewData && viewType === "contract") {
      contract();
    }
    if (viewData && viewType === "reasons") {
      reasons();
    }
  }, [viewData, viewType]);

  const handleViewSearch = async (data) => {
    setViewData(data);
    setViewType("search");
    handleGenerateIdQuery(data.id);
  };

  const handleWritingContract = async (data) => {
    setViewData(data);
    setViewType("contract");
    setIdProposalIsLoading(data.id);
    handleGenerateIdQuery(data.id);
  };

  const handleShowReasons = async (data) => {
    setViewData(data);
    setViewType("reasons");
    handleGenerateIdQuery(data.id);
  };

  const handleCardRender = (data) => {
    const showClickWriting =
      data.status === PROPOSAL_STATUS.APROVADOP1 ||
      data.status === PROPOSAL_STATUS.APROVADOP2_NAO_ASSINADO ||
      data.status === PROPOSAL_STATUS.APROVADA_MESA_NAO_ASSINADO;

    const showHandleViewSearch =
      data.status === PROPOSAL_STATUS.APROVADOP2_ASSINADO ||
      data.status === PROPOSAL_STATUS.APROVADOP2_NAO_ASSINADO ||
      data.status === PROPOSAL_STATUS.PENDENTE_MESA_CREDITO;

    const showReasons = data.status === PROPOSAL_STATUS.APROVADOP1;
    return (
      <Card
        onClickSearch={showHandleViewSearch && handleViewSearch}
        onClickWriting={showClickWriting && handleWritingContract}
        onClickShowReasons={showReasons && handleShowReasons}
        key={data.id}
        data={data}
        isLoading={idProposalIsLoading === data.id}
      />
    );
  };

  const handleCardListRender = useMemo(
    () => proposals?.map(handleCardRender),
    [proposals]
  );

  return (
    <S.Container>
      <Loading
        status={
          GetProposalStatus.loading ||
          GetBenefits.loading ||
          GetProposalByStatus.loading ||
          GetSpcProposalData.loading
        }
      />

      <OneButtonPopup
        defaultButton
        text={msgDialog}
        buttonText="Entendi"
        visibility={dialogIsOpen}
        setVisibility={() => setDialogIsOpen(false)}
      />

      <OneButtonPopup
        defaultButton
        title="Motivo"
        text={msgDialog}
        buttonText="Entendi"
        visibility={pendingDialogIsOpen}
        onClose={() => {
          setViewData(null);
        }}
        setVisibility={() => setpendingDialogIsOpen(false)}
      />

      <Layout routeBack="/opcoes-acesso" title="Consulta Status da Proposta">
        {FILTER_OPTIONS.FILTRO === optionSelected && (
          <S.ContentInput>
            <Input
              id="name"
              name="userFullName"
              label="Nome completo"
              placeholder=""
              type="text"
              defaultValue={name}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Grid xs={1} lg={3} gap={15}>
              <Input
                name="cpf"
                label="CPF"
                placeholder="000.000.000-00"
                type="number"
                value={cpf?.masked}
                max={14}
                onChange={(e) => setCpf(cpfMask(e.target.value))}
                inputMode="numeric"
              />

              <Input
                label="De"
                placeholder="00/00/0000"
                type="number"
                value={from?.masked}
                max={10}
                onChange={(e) => setFrom(dateMask(e.target.value))}
                iconRight={themes.components.icons.calendar}
                inputMode="numeric"
              />

              <Input
                label="Até"
                placeholder="00/00/0000"
                type="number"
                value={to?.masked}
                max={10}
                onChange={(e) => setTo(dateMask(e.target.value))}
                iconRight={themes.components.icons.calendar}
                inputMode="numeric"
              />
            </Grid>
          </S.ContentInput>
        )}

        <Grid xs={1} lg={2}>
          <ContainerFlex row horizontal center wrap>
            <InputRadio
              label="Aprovadas"
              onChange={(e) => {
                e.persist();
                setOptionSelected(e.target.value);
              }}
              value={FILTER_OPTIONS.APROVADA}
              checked={FILTER_OPTIONS.APROVADA === optionSelected}
            />

            <InputRadio
              label="Não Aprovadas"
              onChange={(e) => {
                e.persist();
                setOptionSelected(e.target.value);
              }}
              value={FILTER_OPTIONS.REPROVADA}
              checked={FILTER_OPTIONS.REPROVADA === optionSelected}
            />

            <InputRadio
              label="Filtro"
              value={FILTER_OPTIONS.FILTRO}
              onChange={(e) => {
                e.persist();
                setOptionSelected(e.target.value);
              }}
              checked={FILTER_OPTIONS.FILTRO === optionSelected}
            />
          </ContainerFlex>
          <ContainerFlex row horizontal center>
            <Button
              buttonType="primary"
              text={"Buscar"}
              onClick={searchStrategy}
              buttonSize="small"
              style={{ maxWidth: "300px" }}
            />
          </ContainerFlex>
        </Grid>
      </Layout>

      <S.WrapperList>{handleCardListRender}</S.WrapperList>

      <ContainerFlex
        row
        horizontal
        center
        marginTop={20}
        style={{
          display: showPagination && proposals.length ? "flex" : "none",
        }}
      >
        <Pagination
          totalPages={totalPages}
          onCurrentPageChange={handleApprovedOrDisapprovedSearch}
        />
      </ContainerFlex>

      {!proposals?.length && (
        <ContainerFlex row horizontal center>
          <S.NotFound>Nenhuma proposta encontrada</S.NotFound>
        </ContainerFlex>
      )}
    </S.Container>
  );
}

export default SearchProposalQuery;
