import CryptoJS from "crypto-js";

export const decryptAES_CBC = async (key, iv, data) => {
  const decrypted = CryptoJS.enc.Utf8.stringify(
    CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      padding: CryptoJS.pad.NoPadding,
      mode: CryptoJS.mode.CBC,
    })
  ); // Message BarCode

  console.log("decryptAES_CBC --> ", decrypted);
  return decrypted;
};

export const encryptionAES_CBC = async (key, iv, data) => {
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  console.log("encryptionAES_CBC --> ", encrypted.toString());
  return encrypted.toString();
};
