import styled, { keyframes } from "styled-components";
import media from "styled-media-query";

export const Container = styled.div`
  margin: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

export const IconCardDesign = styled.img`
  width: 80px;
  border-radius: 4px;
  filter: drop-shadow(0 1px 0.1rem);
  margin: 0 10px;

  @media (max-width: 712px) {
  width: 70px;
  height: 60px;
  border-radius: 4px;
  filter: drop-shadow(0 1px 0.1rem);
  margin: 0 10px;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
`;

export const TableHistory = styled.table`
  width: 100%;
  border-spacing: 0;

  td {
    text-align: center;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: grey;
    padding: 10px;
  }

  th {
    padding: 10px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: grey;
  }
`;
export const Text = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: ${({
    theme: {
      sizes: { small },
    },
  }) => small};
  font-weight: ${({
    theme: {
      fontWeight: { fontWeightRegular },
    },
  }) => fontWeightRegular};
  color: ${({
    theme: {
      colors: { blackLight },
    },
  }) => blackLight};

  ${media.greaterThan("medium")`
    font-size: ${({
      theme: {
        sizes: { medium },
      },
    }) => medium};
  `}
`;

export const ErrorMessage = styled.span`
  color: ${({
    theme: {
      colors: { red },
    },
  }) => red};
  font-size: 1.5rem;
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  margin-bottom: 30px;
`;

export const ClientContent = styled.div`
  display: flex;
  font-size: 1.5em;

  @media screen and (max-width: 410px) {
    font-size: 1em;
  } ;
`;

export const WrapperClient = styled.div`
  display: flex;
  margin: 5%;
`;

export const WrapperList = styled.div`
  margin-top: 30px;
`;

export const labelTitle = styled.span`
  margin-right: 5px;
  color: gray;
  white-space: nowrap;
`;

export const labelValue = styled.span`
  color: black;
  white-space: nowrap;
`;

export const NotFound = styled.span`
  font-size: 3rem;
  color: grey;
  text-align: center;
  margin-top: 30%;
`;

export const divClient = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px 10px 0;
`;

export const divRadioBtn = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  padding: 10px;
  gap: 30px;

  @media (max-width: 712px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Picture = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    justify-content: center;
    margin-top: -5%;
  }

  #picture-modal {
    margin-right: 5px;
  }

  button {
    display: flex;
    align-items: center;
    width: auto !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  }
`;

export const TextArea = styled.textarea`
  padding: 16px;
  font-size: 1.1em;
  font-weight: 300;
  border: 1px solid;
  width: 100%;
  outline: none;
`;

export const ContentInput = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2%;
  margin-top: 0%;
`;

export const ContentInputSameRow = styled.div`
   width: 100%;
  margin-right: 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const SingleInputSameRow = styled.div`
  width: 100%;
  margin-right: 3%;

  @media screen and (max-width: 500px) {
    width: 380px;
    margin-left: calc(0.452 * 100vw - 208.8px); /* Ajuste proporcional de margin-left */
  }
`;

export const AttendanceDetailContainer = styled.div`
  position: relative; /* Para permitir o uso de translateY */
  margin-top: -30px;

  @media screen and (max-width: 500px) {
    margin-top: -10px;
  }

  @media screen and (min-width: 1180px) {
    transform: scale(0.9) translateY(-5%);
  }

  @media screen and (max-height: 1000px) {
    transform: scale(0.8) translateY(-13%);
  }
`;

export const OpenSalesContainer = styled.div`
  margin-left: 10px;
  margin-top: -30px;
`;
